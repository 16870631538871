import React from 'react';

const projects = [
    {
        title: 'První aplikace',
        description: 'Prvni seznámení s Reactem a jsx',
        githubLink: 'umdemy-react-firstapp',
        demoLink: 'first-app'
    },
    {
        title: 'Komponenty',
        description: 'Prvni seznámení s komponentami',
        githubLink: 'umdemy-react-components',
        demoLink: 'components'
    },
    {
        title: 'Počasí',
        description: 'Prvni opravdová aplikace - pracuje s aktuální polohou uživatele a datem a podle toho rozhodne jestli je aktuálně u uživatele teplo nebo zima',
        githubLink: 'umdemy-react-seasons',
        demoLink: 'seasons'
    },
    {
        title: 'Fotobanka',
        description: 'Kombinace hooků a api requestů',
        githubLink: 'umdemy-react-pics',
        demoLink: 'pics'
    },
    {
        title: 'Youtube',
        description: 'Podobná aplikace jako fotobanka',
        githubLink: 'umdemy-react-videos',
        demoLink: 'videos'
    },
    {
        title: 'Závěrečná aplikace',
        description: 'Kombinuje vše co bylo v kurzu - router, komponety, callbacky, hooky (překladač nefunguje kvůlu placené api, ale na localhostu funguje :))',
        githubLink: 'umdemy-react-widgets',
        demoLink: 'widgets'
    }
];


const Menu = () => {
    const renderedProject = projects.map((project) => {
        let demoLinkDiv;
        let githubLinkDiv;

        if(project.demoLink){
            demoLinkDiv = <a href={'https://react.tomasklatt.cz/' + project.demoLink}><div className="demoLink">demo</div></a>;
        } else {
            demoLinkDiv = null;
        }

        if(project.githubLink){
            githubLinkDiv = <a href={'https://github.com/tomasklatt/' + project.githubLink}><div className="githubLink">github</div></a>;
        } else {
            githubLinkDiv = null;
        }

        return (
            <div className="project">
                <div className="title">{project.title}</div>
                <div className="description">{project.description}</div>
                {demoLinkDiv}
                {githubLinkDiv}
            </div>
        );
    });

    return (
        <div className="menu">{renderedProject}</div>
    );
}

export default Menu;