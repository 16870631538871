import React from "react";
import Menu from "./Components/Menu";

const App = () => {
    return(
        <div>
            <Menu/>
        </div>
    );
}

export default App;